:root {
  //topbar
  --#{$prefix}heading-bg: #{$white};
  --#{$prefix}topbar-search-bg: #f3f3f9;
  --#{$prefix}header-item-color: #555b6d;

  --#{$prefix}boxed-body-bg: #ebebf4;

  --#{$prefix}topnav-bg: #{$white};
  --#{$prefix}menu-item-color: #545a6d;
  --#{$prefix}heading-dark-bg: #05255b;

  --#{$prefix}input-border-color: #{$gray-400};
  --#{$prefix}input-focus-border-color: #{darken($gray-400, 10%)};
  --#{$variable-prefix}form-check-input-border: #{rgba($black, 0.1)};

  //footer
  --#{$prefix}footer-bg: #f2f2f5;
  --#{$prefix}footer-color: #74788d;

  --#{$prefix}custom-white: #{$white};
  --#{$prefix}hr-border-color: #{gray-400};

  // TEST

  // Antd table
  .ant-table {
    background-color: transparent;

    .ant-table-column-sorter {
      color: rgb(73, 80, 87);
    }

    .ant-table-cell {
      color: rgb(73, 80, 87);
    }
  }

  .ant-table-thead > tr > th {
    background-color: rgb(200, 212, 220);
  }

  .clear-header {
    .ant-table-thead > tr > th {
      background-color: transparent;
    }
  }

  .ant-table-small {
    font-size: 13px;
  }

  .ant-table-cell-row-hover {
    background-color: rgb(234, 234, 234) !important;
  }

  .striped {
    .ant-table-tbody > tr:nth-child(odd) {
      background-color: #fafafa;

      .ant-table-cell-fix-left {
        background-color: #fafafa;
      }
    }

    .ant-table-tbody > tr:nth-child(even) {
      background-color: transparent;

      .ant-table-cell-fix-left {
        background-color: #fff;
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td.ant-table-cell-fix-left {
      background-color: rgb(230, 247, 255);
    }
  }

  [data-style="0"] {
    // $primary does not work. Has to be changed if primary color changes
    .topnav .navbar-nav .dropdown-item.active,
    .topnav .navbar-nav .dropdown-item:hover {
      color: #00a0d6 !important;
    }
    .topnav .navbar-nav .dropdown.active > a {
      color: #00a0d6 !important;
    }
  }

  // groupCallRange radio-buttons
  // .group-call-range {
  label.ant-radio-button-wrapper ant-radio-button-wrapper-checked {
    border-right-color: #00a0d6 !important;
    // border-color: #00d60b;
    // color: #00d60b;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #00a0d6 !important;
    background: #ffffff00;
    border-color: #00a0d6 !important;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):before {
    background-color: #00a0d6 !important;
  }
  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #00a0d6 !important;
  }
  .ant-radio-button-wrapper {
    color: #545a6d;
    font-size: 12px;
    padding: 0px 15px;
    // TODO: dark-theme color & border
    background: #ffffff00;
  }
  // .badge-soft-primary {
  //   color: #00a0d6 !important;
  //   background-color: #00a0d626 !important;
  // }
  // }
}
