// :root CSS dark variables

[data-layout-mode="dark"][data-style="10"] {
  // Color system - Dark Mode only
  $gray-100: #212529;
  $gray-200: #2a3042;
  $gray-300: #32394e;
  $gray-400: #a6b0cf;
  $gray-500: #bfc8e2;
  $gray-600: #c3cbe4;
  $gray-700: #f6f6f6;
  $gray-800: #eff2f7;
  $gray-900: #f8f9fa;

  $grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  );

  // Prefix for :root CSS variables
  @each $color, $value in $grays {
    --#{$prefix}gray-#{$color}: #{$value};
  }

  // FONT COLORS
  .text-muted {
    color: #c3cbe4 !important;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #{$gray-600};
  }

  p {
    color: #{$gray-400} !important;
  }
  //  THEME
  $icon-primary: #564ab1;
  $icon-primary-darker: #392e88;
  $suvanto-color-dark: #038ddd;
  $blue: #556ee6;
  $indigo: #564ab1;
  $purple: #6f42c1;
  $pink: #e83e8c;
  $red: #f46a6a;
  $red2: #bd2c2c;
  $red3: #ff6e6e;
  $orange: #f1734f;
  $yellow: #ffd000;
  $green: #34c38f;
  $teal: #050505;
  $cyan: #50a5f1;
  $suvanto-light: #dfecff;
  $suvanto-dark: #42b6f8;
  $suvanto-darker-bg: #038ddd;
  $suvanto-var-blue: #558ee6;
  $suvanto-blue: #00bcf5; //4dc6f3// USED IN SMALL ICONS
  $suvanto-blue-3-1: #00a0d6;
  $suvanto-light-bg: #d1e1e7;
  $suvanto-blue-45-1: #0080a9; //#007DA3 //0080A9
  $suvanto-green: #49a02a; //8dd22c
  $suvanto-orange: #ff9b00;
  $suvanto-dark-blue: #224465;
  $loading-blue-dots: #0061db;
  $light-card: #f7f7f7;
  // scss-docs-end color-variables
  $chart-gateway: #0cc744;
  $chart-activity: #00a0d6;
  $chart-electricity: #0026ff; //#186bfc
  $chart-door: #8dd169; //0cc770
  $chart-bed: #b0b0b0;
  $chart-dining: #7871c4; // #adb3eb;
  $chart-wc: #ff9b00;
  $chart-color-purple: #ac52da;
  $chart-color-suvantoblue: #00bcf5;
  $statusbar-suvantoblue: #3bc5ee;
  $chart-color-lowhumidity: #7abcf7;
  $chart-color-temp: #ff954e;
  $chart-color-templow: #fcda69;
  $chart-color-temphigh: #ff4c2c;
  $yellow2: #df8600;
  // donut-chart-colors
  $chart-color1: #f5d663;
  $chart-color2: #f5a14a;
  $chart-color3: #48d0f8;
  $chart-color4: #3783cc;
  $chart-color5: #1c3763;
  $chart-color6: #0a9396;
  $chart-color7: #94d2bd;
  $green-card-messages: #81cfb3;
  $dark-background: #222736;

  $primary: $chart-color6;
  $darker-primary: $icon-primary-darker;

  //$secondary: $gray-600;
  $secondary: $suvanto-green;
  $tertiary: $suvanto-orange;
  $success: $green;
  $info: $cyan;
  $warning: $yellow;
  $warning2: $yellow2;
  $danger: $red;
  $danger2: $red2;
  $danger3: $red3;
  $light: $gray-200;
  $dark: $gray-800;
  $icon-primary: $icon-primary;
  // // scss-docs-end theme-color-variables

  .page-title-box {
    h4 {
      color: #{$gray-500};
    }
  }
  background-color: #222736;
  // --#{$prefix}body-bg: #222736 !important;
  --#{$prefix}body-bg-rgb: rgb(34, 39, 54);
  --#{$prefix}body-color-rgb: #{to-rgb($gray-400)};
  --#{$prefix}body-color: #{$gray-400};
  --#{$prefix}heading-color: #{$gray-400} !important;
  --#{$prefix}border-color: #{$gray-300};
  --#{$prefix}custom-white: #{$gray-200};

  --#{$prefix}input-border-color: #{$gray-300};
  --#{$prefix}input-focus-border-color: #{lighten($gray-300, 4%)};
  --#{$prefix}hr-border-color: #{$gray-300};
  --#{$variable-prefix}form-check-input-border: #{lighten($gray-300, 4%)};

  // component variable
  --#{$prefix}light: #{$gray-300};
  --#{$prefix}light-rgb: #{to-rgb($gray-300)};
  --#{$prefix}dark: #{$gray-700};
  --#{$prefix}dark-rgb: #{to-rgb($gray-700)};
  --#{$prefix}primary: #{$primary};
  --#{$prefix}primary-rgb: #{to-rgb($primary)};
  --#{$prefix}secondary: #{$gray-700};
  --#{$prefix}secondary-rgb: #{to-rgb($gray-700)};
  //  text variable
  --#{$prefix}text-light: #{$gray-300};
  --#{$prefix}text-light-rgb: #{to-rgb($gray-300)};
  --#{$prefix}text-dark: #{$gray-400} !important;
  --#{$prefix}text-dark-rgb: #{to-rgb($gray-400)};
  --#{$prefix}text-primary: #{$primary} !important;
  --#{$prefix}text-primary-rgb: #{to-rgb($primary)};

  --#{$prefix}text-darker-primary: #{$darker-primary} !important;
  --#{$prefix}text-darker-primary-rgb: #{to-rgb($darker-primary)} !important;
  --#{$prefix}text-secondary: #{$gray-700};
  --#{$prefix}text-secondary-rgb: #{to-rgb($gray-700)};

  //topbar
  --#{$prefix}heading-bg: #262b3c;
  --#{$prefix}header-item-color: #{$gray-400};
  --#{$prefix}topbar-search-bg: #{$gray-200};

  --#{$prefix}heading-dark-bg: #{$primary};

  --#{$prefix}boxed-body-bg: #32394f;

  --#{$prefix}topnav-bg: #282e3f;
  --#{$prefix}menu-item-color: #a6b0cf;
  --#{$prefix}menu-item-active-color: #{$primary};

  // BTN
  .btn-primary {
    --#{$prefix}btn-bg: #{$primary};
    --#{$prefix}btn-border-color: #{$primary};
    --#{$prefix}btn-hover-bg: #{darken($primary, 4%)} !important;
    --#{$prefix}btn-active-bg: #{darken($primary, 7%)} !important;
    --#{$prefix}btn-active-border-color: #{darken($primary, 7%)} !important;
  }
  .btn-secondary {
    --#{$prefix}btn-bg: #{$suvanto-green};
    --#{$prefix}btn-border-color: #{$suvanto-green};
    --#{$prefix}btn-hover-bg: #{darken($suvanto-green, 4%)} !important;
    --#{$prefix}btn-active-bg: #{darken($suvanto-green, 7%)} !important;
    --#{$prefix}btn-active-border-color: #{darken($suvanto-green, 7%)} !important;
  }
  .btn-outline-primary {
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-color: #{$primary};
    --#{$prefix}btn-border-color: #{$primary};
    --#{$prefix}btn-hover-bg: #{$primary};
    --#{$prefix}btn-hover-color: #{$white};
  }
  .btn-outline-secondary {
    --#{$prefix}btn-bg: transparent;
    --#{$prefix}btn-color: #{$suvanto-green};
    --#{$prefix}btn-border-color: #{$suvanto-green};
    --#{$prefix}btn-hover-bg: #{$suvanto-green};
    --#{$prefix}btn-hover-color: #{$white};
  }
  // HR
  --#{$prefix}border-color: #{$gray-200} !important;
  .dropdown-divider {
    border-top-color: #3c4758ea;
  }

  //footer
  --#{$prefix}footer-bg: #262b3c;
  --#{$prefix}footer-color: #{$gray-400};

  .card {
    --#{$prefix}card-bg: #{$gray-200};
  }

  .list-group {
    --#{$prefix}list-group-bg: #{$gray-200};
    --#{$prefix}list-group-color: #{$gray-900};
    --#{$prefix}list-group-border-color: #{$gray-300};
  }

  .btn-light {
    --#{$prefix}btn-color: #{$gray-600};
    --#{$prefix}btn-bg: #{$gray-300};
    --#{$prefix}btn-border-color: #{$gray-300};
  }

  .btn-outline-light {
    --#{$prefix}btn-color: #{$gray-600};
    --#{$prefix}btn-border-color: #{$gray-300};
  }

  .btn-light,
  .btn-outline-light {
    --#{$prefix}btn-hover-color: #{$gray-600};
    --#{$prefix}btn-hover-bg: #{$gray-300};
    --#{$prefix}btn-hover-border-color: #{$gray-300};
    --#{$prefix}btn-active-color: #{$gray-600};
    --#{$prefix}btn-active-bg: #{$gray-300};
    --#{$prefix}btn-active-border-color: #{$gray-300};
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($gray-300)};
  }

  .btn-dark {
    --#{$prefix}btn-bg: #{$gray-400};
    --#{$prefix}btn-border-color: #{$gray-400};
    --#{$prefix}btn-hover-bg: #{$gray-400};
  }

  .btn-outline-dark {
    --#{$prefix}btn-color: #{$gray-400};
    --#{$prefix}btn-border-color: #{$gray-400};
  }

  .btn-dark,
  .btn-outline-dark {
    --#{$prefix}btn-hover-bg: #{$gray-400};
    --#{$prefix}btn-hover-border-color: #{$gray-400};
    --#{$prefix}btn-active-bg: #{$gray-400};
    --#{$prefix}btn-active-border-color: #{$gray-400};
    --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($gray-600)};
  }

  .card {
    border: $card-border-width solid tint-color(#2a3042, 5%);
  }

  .dropdown-menu {
    --#{$prefix}dropdown-bg: #{$gray-200};
    --#{$prefix}dropdown-link-color: #{$gray-400};
    --#{$prefix}dropdown-border-color: #{$gray-300};
    --#{$prefix}dropdown-border-width: 1px;
    --#{$prefix}dropdown-divider-bg: #{$gray-300};
    --#{$prefix}dropdown-link-hover-bg: #{$gray-300};
    --#{$prefix}dropdown-link-hover-color: #e9ecef;
    --#{$prefix}dropdown-link-active-bg: #{$gray-300};
    --#{$prefix}dropdown-link-active-color: #e9ecef;
  }

  .modal {
    --#{$prefix}modal-bg: #{$gray-200};
  }

  .offcanvas,
  .offcanvas-lg,
  .offcanvas-md,
  .offcanvas-sm,
  .offcanvas-xl,
  .offcanvas-xxl {
    --#{$prefix}offcanvas-bg: #{$gray-200};
  }

  .nav-tabs {
    --#{$prefix}nav-tabs-link-active-bg: #{$gray-200};
    --#{$prefix}nav-tabs-link-active-border-color: #{lighten($gray-300, 4%)};
    --#{$prefix}nav-tabs-border-color: #{lighten($gray-300, 4%)};
    --#{$prefix}nav-tabs-link-hover-border-color: #{lighten($gray-300, 4%)};
    --#{$prefix}nav-tabs-link-active-color: #{$primary};
  }

  .accordion {
    --#{$prefix}accordion-active-bg: #{rgba($primary, 0.1)};
  }

  .toast {
    --#{$prefix}toast-header-bg: #{$gray-200};
    --#{$prefix}toast-header-color: #{$gray-600};
    --#{$prefix}toast-bg: #{$gray-200};
  }

  .table {
    --#{$prefix}table-striped-bg: #{rgba($gray-500, 0.05)};
    --#{$prefix}table-hover-bg: #{rgba($gray-500, 0.05)};
    --#{$prefix}table-active-bg: #{rgba($gray-500, 0.05)};
  }

  .table-light {
    --#{$prefix}table-color: #{$gray-400};
    --#{$prefix}table-bg: #{$gray-300};
    --#{$prefix}table-border-color: #{$gray-300};
  }

  .table-dark {
    --#{$prefix}table-color: #{$gray-400};
    --#{$prefix}table-bg: #{$gray-300};
    --#{$prefix}table-border-color: #{lighten($gray-300, 4%)};
  }

  .icon-tab .nav-link {
    background-color: #{$gray-200};
    color: $gray-600;
  }
  .icon-tab .active.nav-link {
    background: #{$primary}; //#556ee6;
    color: #fff;
  }
  .topnav .navbar-nav .nav-item .nav-link.active {
    // background: #{lighten($primary, 40%)};
    color: #{$primary};
  }

  // btn-link
  .btn-link {
    --#{$prefix}btn-color: #{$primary};
    --#{$prefix}btn-hover-color: #{darken($primary, 4%)};
    --#{$prefix}btn-active-color: #{darken($primary, 4%)};
  }

  // ANTD
  .ant-drawer-header {
    background: rgba(0, 0, 0, 0.85);
  }
  .ant-drawer-header-title {
    color: $gray-600;
  }
  .ant-select-selector,
  .ant-select-selection,
  .ant-select-selection-item,
  .ant-select-selection-search,
  .ant-select-selection-search-mirror,
  .ant-select-dropdown,
  .ant-select-dropdown-item,
  .ant-select-open,
  .ant-tree-select,
  .ant-select-multiple,
  .ant-select,
  .ant-select-selection-overflow,
  .ant-tree-node-content-wrapper,
  .ant-select.ant-select-disabled .ant-select-selector {
    background-color: #32394e;
    color: #a6b0cf !important;
    border-color: #3a425a !important;
  }

  // Ant select
  //CUSTOM
  // #heavy .ant-tree-node-content-wrapper {
  //   background-color: transparent;
  //   border-color: transparent;
  // }
  #heavy .ant-select-selection-item,
  #heavy .ant-select-dropdown,
  #heavy .ant-select-selector,
  #heavy .ant-select-selection,
  #heavy .ant-select-selection-item,
  #heavy .ant-select-selection-search,
  #heavy .ant-select-dropdown-item,
  #heavy
    ant-select.ant-select-lg.ant-select-borderless.ant-select-single.ant-select-allow-clear.ant-select-show-arrow.ant-select-show-search {
    font-weight: 600;
    font-size: large;
    letter-spacing: 0.02rem;
    color: $gray-700;
    border-color: #e9ecef00 !important;
    background-color: #e9ecef00 !important;
  }

  // #heavy .ant-select-selection-item:hover,
  #heavy .ant-select-selection-search:hover,
  #heavy .ant-select-dropdown-item:hover {
    // font-weight: 600;
    // font-size: large;
    // letter-spacing: 0.02rem;
    // color: $gray-700;
    background-color: #e9ecef3f !important;
  }
  #heavy .ant-select-selection-placeholder {
    font-weight: 600;
    font-size: large;
    letter-spacing: 0.02rem;
    color: $gray-700;
  }
  .product-view-nav {
    .nav-item {
      .nav-link.active {
        color: #{$white};
        // background-color: #d5eff9;
      }
    }
  }
  .nav-item {
    .nav-link.active {
      color: #{$primary};
      // background-color: #d5eff9;
    }
  }
  #navigation-management {
    .navbar-nav {
      .nav-item {
        .nav-link.active {
          color: #{$primary};
          background-color: #d5eff9;
        }
      }
      .nav-item {
        .dropdown-menu {
          .dropdown-item {
            color: #{$gray-500};
            &.active,
            &:hover {
              color: #{$primary};
            }
          }
        }
      }
      .nav-link {
        color: #{$gray-500};
        &:focus,
        &:hover {
          color: #{$primary};
          background-color: transparent;
        }
      }

      > .dropdown-toggle {
        &.active {
          > a {
            color: #{$primary};
            background-color: transparent;
          }
        }
      }
    }
  }

  // Time picker
  .ant-picker-content th,
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-prev-btn,
  .ant-picker-header-super-next-btn,
  .ant-picker-header-next-btn,
  .ant-picker-header {
    color: $gray-500;
  }
  .ant-picker,
  .ant-picker-input,
  .ant-picker-range,
  .ant-picker-panel-container,
  .ant-picker-cell,
  .ant-select-open,
  .ant-select-open,
  .ant-select-selection-search,
  .ant-select-selection-placeholder,
  .ant-select-item-option-content,
  .ant-select-item,
  .ant-select-tree-dropdown {
    background-color: #32394e;
    color: #a6b0cf !important;
    border-color: #3a425a !important;
  }
  .ant-picker-input > input {
    color: #a6b0cf !important;
  }

  .ant-picker-cell-in-range,
  .ant-picker-cell-in-view {
    color: $gray-500;
    border-color: #313749 !important;
    background-color: #222736 !important;
  }
  .ant-picker-time-panel-cell-inner {
  }
  .ant-picker-time-panel-cell-selected {
  }
  .ant-picker-time-panel-cell {
    color: $gray-500;
    border-color: #313749 !important;
    background-color: #222736 !important;
  }

  // Antd table
  .ant-table {
    background-color: transparent;

    .ant-table-cell {
      color: rgb(166, 176, 207);
    }

    &.ant-table-bordered {
      table {
        border-top-color: rgba(255, 255, 255, 0.1) !important;
      }

      .ant-table-container {
        border-left-color: rgba(255, 255, 255, 0.1) !important;
      }
    }

    td.ant-table-column-sort {
      background-color: rgb(42, 48, 66);
    }

    .ant-table-column-sorter {
      color: rgb(166, 176, 207);
    }

    .ant-table-cell {
      border-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .ant-table-thead > tr > th {
    background-color: #314c68;
    color: rgb(166, 176, 207);
  }

  .ant-table-cell-row-hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .ant-table-cell-fix-left {
    background-color: rgb(42, 48, 66);
  }

  .striped {
    .ant-table-tbody > tr:nth-child(odd) {
      background-color: shade-color(#2a3042, 8%);

      .ant-table-cell-fix-left {
        background-color: shade-color(#2a3042, 8%);
      }
    }

    .ant-table-tbody > tr:nth-child(even) {
      background-color: transparent;

      .ant-table-cell-fix-left {
        background-color: rgb(42, 48, 66);
      }
    }
  }

  // Group card hover
  .group-cards:hover {
    box-shadow: 1px 1px 1px 1px #{$primary};
  }

  // Card bg
  .bg-primary.bg-soft {
    background-color: #eafaff !important;
  }

  .down-arrow-icon {
    background-color: #222736 !important;
  }

  // Ant table variable

  // Antd table
  // .ant-table {
  //   background-color: transparent;
  //   color: rgb(166, 176, 207);
  // }

  // .ant-table-thead > tr > th {
  //   background-color: #222736 !important;
  //   color: $gray-600;
  //   border-color: #222736 !important;
  // }

  // .ant-table-tbody > tr > td {
  //   border-bottom-color: rgba(255, 255, 255, 0.1);
  // }

  // tbody {
  //   .ant-table-cell-fix-left {
  //     background-color: #222736de !important;
  //   }
  // }

  // .ant-table-cell-row-hover {
  //   background-color: rgba(255, 255, 255, 0.1) !important;
  // }
  // .ant-pagination-item {
  //   background-color: #32394e !important;
  //   color: $gray-600;
  // }
  // .ant-select-arrow {
  //   color: $gray-500;
  // }
  // .ant-table-row-selected {
  //   color: $gray-300;
  // }
  // .ant-table-row-selected .ant-table-cell-row-hover {
  //   color: $gray-700 !important;
  // }
  .ant-pagination-item-link {
    color: $gray-600;
  }
  .ant-select-arrow {
    color: $gray-400;
  }

  .ant-select-clear {
    color: $gray-600;
    background: #fff0;
  }

  // MUI CHIP
  .MuiChip-root {
    color: rgba(0, 0, 0, 0.87);
    // font-family: "Poppins", sans-serif;
    background-color: $gray-400;
  }

  // Bulletins card
  .nav-tabs-custom .nav-item .nav-link.active {
    background-color: transparent;
    color: #{$primary} !important;
  }
  .nav-tabs-custom .nav-item .nav-link.hover {
    background-color: transparent;
    color: #{$primary} !important;
  }
  .nav-tabs-custom .nav-item .nav-link::after {
    background-color: #{$primary};
  }

  // checkbox
  .form-check-input {
    border-color: #{$primary} !important;
  }
  .form-check-input:checked {
    background-color: #{$primary} !important;
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem #{$primary} !important;
  }
  li {
    color: #{$primary};
  }

  // $primary does not work. Has to be changed if primary color changes
  .topnav .navbar-nav .dropdown-item.active,
  .topnav .navbar-nav .dropdown-item:hover {
    color: #0a9396 !important;
  }
  .topnav .navbar-nav .dropdown.active > a {
    color: #0a9396 !important;
  }
  // antd table label color
  label.ant-radio-wrapper {
    color: #{var(--#{$prefix}body-color)} !important;
  }
  // antd collapse arrow
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    color: #{var(--#{$prefix}body-color)} !important;
  }

  #navigation-management .navbar-nav .nav-item .nav-link.active {
    background-color: #dbf1f2e6 !important;
  }
  // .badge-soft-primary {
  //   color: #0a9396 !important;
  //   background-color: #0a949626 !important;
  // }
}
