.ripple {
  position: relative;
  overflow: auto;
  transform: translate3d(0, 0, 0);
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.2;
  transition: 0s;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background: radial-gradient(circle, #000 10%, transparent 10.01%) no-repeat
    50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}

.ripple-style {
  /* height: 90px !important; */
  overflow: hidden;
  padding-left: 0;
  padding-top: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-size: 0.8rem; */
  /* font-weight: 300; */
  margin-left: 0.3rem;
  margin-top: 0;
  z-index: 2 !important;

  .ripple-title {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff !important;
  }
  .ripple-text {
    font-size: 12px !important;
    font-weight: lighter !important;
    /* color: #000000 !important; */

    color: #ffffff !important;
  }
}
.item-render-style {
  border-radius: 4;
  /* height: 100px !important; */
}
.rct-item {
  height: 45px !important;
  line-height: 20px !important;
  font-weight: 300 !important;
  margin-top: -7.5px !important;
}
