/* =============
   General
============= */

body {
  background-color: #f8f8f8; //rgba(251, 250, 248, 0.8) //fafafa
}

html {
  position: relative;
  min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-700;
}

a {
  text-decoration: none !important;
}

label {
  font-weight: 500;
  margin-bottom: $form-label-margin-bottom;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}

// container

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

// row

.row {
  > * {
    position: relative;
  }
}

// Dark
// body[theme="dark"] {
//   .h1,
//   .h2,
//   .h3,
//   .h4,
//   .h5,
//   .h6,
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     color: $gray-300;
//   }

//   p {
//     color: $gray-500;
//   }

//   .text-dark {
//     color: $gray-200 !important;
//   }
// }

// // High contrast
// body[theme="contrast"] {

//   .text-primary {
//     color: $suvanto-blue-45-1 !important;
//   }

//   .text-muted {
//     color: #70768C !important;
//   }

//   span {
//     > a {
//       color: $suvanto-blue-45-1 !important;
//     }
//   }
// }
